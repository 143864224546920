import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { InMemoryCache } from "@apollo/client/cache";
import { ApolloProvider, ApolloClient, HttpLink } from "@apollo/client";
import App from "./App";
import possibleTypes from "./possibleTypes.json";

const link = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL,
    // Additional fetch options like `credentials` or `headers`
});

const cache = new InMemoryCache({
    possibleTypes,
});

const client = new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link: link,
    cache: cache,
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
