import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Page } from "./Page";
import { SiteHeader } from "./Components";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { CssBaseline } from "@material-ui/core";
import { Helmet } from "react-helmet";
import LazyMedia from "./Components/Media/LazyMedia";

const App = () => {
    let images = document.querySelectorAll("img");
    images.forEach((image) => {
        LazyMedia(image);
    });

    return (
        <MuiThemeProvider theme={theme}>
            <Helmet>
                <style>
                    @import
                    url('https://fonts.googleapis.com/css2?family=Convergence&family=Francois+One&display=swap');
                </style>
            </Helmet>
            <CssBaseline />
            <Router>
                <SiteHeader />
                <Route
                    path="*"
                    exact
                    component={(props) => <Page {...props} />}
                />
            </Router>
        </MuiThemeProvider>
    );
};

export default App;
