const styles = theme => ({
    root: {
        maxWidth: '1600px',
        margin: '0 auto'
    },
    gridTile: {
        cursor: 'pointer',
        borderRadius: '4px',
        '&::before': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: .25,
            pointerEvents: 'none',
            background: 'linear-gradient(0deg, rgba(33,79,42,1) 0%, rgba(33,79,42,0.5018382352941176) 50%, rgba(33,79,42,0.2497373949579832) 100%)'
        }
    },
    tileTitle: {
        position: 'relative',
        zIndex: 1,
        color: 'white',
        margin: '2rem',
        textShadow: '2px 2px 4px rgba(0, 0, 0, .25)'
    },
    tileImage: {
        top: 0,
        left: 0,
        position: 'absolute',
        objectFit: 'cover',
        height: '100%',
        width: '100%'
    },
    dialogImage: {
        maxHeight: 'calc(100vh - 150px)'
    },
    dialogContent: {
        padding: 0,
        '&:first-child': {
            padding: 0
        }
    },
    galleryTitle: {
        margin: '0 0 2rem'
    }
});

export default styles;