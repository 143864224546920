const styles = theme => ({
    richText: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        maxWidth: '1920px',
        margin: '0 auto',
        alignItems: 'flex-start',
        [theme.breakpoints.up('md')]: {
            flexFlow: 'row nowrap'
        },
        '& h1': { ...theme.typography.h1 },
        '& h2': { ...theme.typography.h2 },
        '& h3': { ...theme.typography.h3 },
        '& h4': { ...theme.typography.h4 },
        '& h5': { ...theme.typography.h5 },
        '& h6': { ...theme.typography.h6 },
        '& p': {
            maxWidth: '720px',
            lineHeight: 1.5,
            margin: theme.spacing(3, 0),
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.15rem'
            }
        },
        '& a:not([class*="MuiButton"])': {
            color: theme.palette.primary.main
        }
    },
    article: {},
    imageRight: {
        '& $article': {
            order: 1
        }
    },
    imageWrapper: {
        position: 'relative',
        flex: '0 1 640px',
        height: 0,
        paddingBottom: '75%',
        borderRadius: '4px',
        overflow: 'hidden',
        '& img': {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        }
    },
    button: {
        margin: theme.spacing(2, 0, 0)
    }
});

export default styles;