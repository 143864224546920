import { gql } from "@apollo/client";

export const GQL_QUERY_PRIMARY_MENU = gql`
  query GetMenuByName($id: ID!) {
    menu(id: $id, idType: NAME) {
      id
      menuItems {
        nodes {
          id
          label
          path
        }
      }
    }
  }
`;

export const GQL_QUERY_ACF_OPTIONS = gql`
  query GetACFOptions {
    opties {
      optiesGroep {
        openingstijden
      }
    }
  }
`;
