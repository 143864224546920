const styles = (theme) => ({
    headerNav: {
        display: "flex",
        alignSelf: "center",
        flexShrink: 0,
        flexFlow: "row nowrap",
        height: "100%",
        "& $navLink": {
            margin: "0 0 0 32px",
            color: theme.palette.primary.main,
            letterSpacing: ".5px",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    navTab: {
        textTransform: "none",
        fontSize: "1.25rem",
        minWidth: 0,
        [theme.breakpoints.up(1440)]: {
            padding: "0 1.5rem",
        },
    },
    navTabFlexContainer: {
        height: "100%",
    },
    mobileNav: {
        zIndex: 999,
        position: "fixed",
        right: "32px",
        bottom: "32px",
        [theme.breakpoints.down("sm")]: {
            bottom: "20px",
            right: "20px",
        },
    },
    mobileNavMenu: {
        padding: theme.spacing(3, 4),
        position: "absolute",
        bottom: `calc(100% + ${theme.spacing(1)}px)`,
        right: 0,
        whiteSpace: "nowrap",
        background: theme.palette.primary.main,
        transform: "scale(.5) translate(0, 0)",
        visibility: "hidden",
        opacity: 0,
        transition: "all .2s ease",
        transformOrigin: "bottom right",
        borderRadius: "4px",
    },
    navLink: {
        margin: theme.spacing(0, 0, 1),
        color: "white",
        display: "block",
        textDecoration: "none",
        fontSize: "1.25rem",
        "&:last-child": {
            margin: 0,
        },
    },
    activeNavMenu: {
        transform: "scale(1)",
        visibility: "visible",
        opacity: 1,
        "& span": {
            opacity: 1,
            transform: "none",
            transition: "all .15s .15s ease",
        },
    },
    fabIcon: {
        marginLeft: theme.spacing(1),
    },
    social: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.main,
        marginLeft: "1.5rem",
        marginRight: theme.spacing(2),

        "& svg": {
            marginRight: theme.spacing(0.5),
            width: "2rem",
            height: "2rem",
        },
    },
    socialLink: {
        display: "inherit",
        alignItems: "inherit",
        color: "inherit",
        textDecoration: "none",
        transition: "all .2s ease",

        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
});

export default styles;
