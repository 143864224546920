import React, { useState } from 'react';
import { GridList, GridListTile, Dialog, DialogContent, CircularProgress, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';
import { Media } from '..';
import styles from './styles';

const Gallery = (props) => {
    const { data: { gallery, gallerytitle }, classes } = props;
    const laptop = useMediaQuery('(min-width:1025px)');
    const tabletPortrait = useMediaQuery('(min-width:768px)');

    const [dialog, setDialog] = useState({
        open: false,
        props: {}
    });

    const handleDialogClose = () => setDialog({
        ...dialog,
        open: false
    });

    const handleClick = (event) => {
        setDialog({
            open: true,
            props: {
                src: event.target.getAttribute('src'),
                srcSet:
                    event.target.getAttribute('data-dialog-srcset') ||
                    event.target.getAttribute('srcset'),
                alt: event.target.getAttribute('alt')
            }
        });
    };

    return (
        <div className={classes.root}>
            {gallerytitle &&
                <Typography
                    variant="h3"
                    className={classes.galleryTitle}
                    align="center"
                >
                    {gallerytitle}
                </Typography>
            }
            <GridList
                cols={laptop ? 4 : 2 }
                cellHeight={tabletPortrait ? 275 : 150}
                spacing={8}
            >
                {gallery ? gallery.map((el, idx) => (
                    <GridListTile
                        key={idx}
                        classes={{ tile: classes.gridTile }}
                    >
                        <Media
                            dataSrc={el.srcSet.split(' ')[0]}
                            dataSrcSet={el.srcSet.split(', ').slice(0, 3).join(', ')}
                            dataDialogSrcSet={el.srcSet.split(', ').slice(0, 4).join(', ')}
                            onClick={handleClick}
                            alt={el.title}
                            className={classes.tileImage}
                        />
                    </GridListTile>
                )) : (
                    <CircularProgress />
                )}
            </GridList>
            <Dialog
                open={dialog.open}
                maxWidth="lg"
                onClose={handleDialogClose}
                onClick={handleDialogClose}
            >
                <DialogContent className={classes.dialogContent}>
                    {dialog.props &&
                        <img
                            alt=""
                            className={classes.dialogImage}
                            {...dialog.props}
                        />
                    }
                </DialogContent>
            </Dialog>
        </div>
    )

}

export default withStyles(styles)(Gallery);