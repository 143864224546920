import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { LinearProgress, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { PageHeader } from "../Components/PageHeader";
import { Tiles, RichText, Gallery, Video } from "../Components";
import { GQL_QUERY_PAGE_BY_SLUG } from "./graphql";
import styles from "./styles";

const Page = (props) => {
    const { classes } = props;

    const [page, setPage] = useState(null);
    const [flexContent, setFlexContent] = useState(null);
    const currentYear = new Date().getFullYear();

    const frontPageSlugs = process.env.REACT_APP_FRONTPAGE_PATHS.split(", ");
    const cleanSlug = props.match.url.toLowerCase().replace(/[^a-z0-9]+/g, "/");

    const slug = frontPageSlugs.includes(cleanSlug)
        ? "frontpage"
        : props.match.url;
    const { loading, data } = useQuery(GQL_QUERY_PAGE_BY_SLUG, {
        variables: {
            slug: slug,
        },
        fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
        if (data) {
            window.scrollTo(0, 0);
            setPage(data.page);
        }
    }, [data]);

    useEffect(() => {
        if (page) {
            setFlexContent(page.flexContent && page.flexContent.flexContent);
        }
    }, [page]);

    return (
        <React.Fragment>
            {loading && <LinearProgress className={classes.linearProgress} />}
            {page && (
                <div className={classes.pageRoot}>
                    <PageHeader
                        data={page}
                        loading={loading}
                        frontPage={page.isFrontPage}
                    />
                    <section className={classNames(classes.pageContent)}>
                        {flexContent &&
                            flexContent.map((block, idx) => (
                                <div
                                    key={idx}
                                    className={classes.flexContentBlock}
                                >
                                    {block.tiles && (
                                        <Tiles data={block.tiles} />
                                    )}
                                    {block.richtext && (
                                        <RichText data={block.richtext} />
                                    )}
                                    {block.video && <Video data={block} />}
                                    {block.gallery && <Gallery data={block} />}
                                </div>
                            ))}
                    </section>
                    <Typography
                        component="footer"
                        className={classes.pageFooter}
                    >
                        <Grid container spacing={8} justify="center">
                            <Grid item>&copy; {currentYear} Kuipers</Grid>
                            <Grid item>
                                Beuningerstraat 36 | 7587 LD De Lutte | (+31)
                                0541-552036
                            </Grid>
                        </Grid>
                    </Typography>
                </div>
            )}
        </React.Fragment>
    );
};

export default withStyles(styles)(Page);
