import React from 'react';
import { Media } from '../../Components';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        maxWidth: '1280px',
        margin: '0 auto',
        '& video': {
            display: 'block',
            margin: '0 auto'
        }
    },
    videoTitle: {
        margin: '0 0 2rem'
    }
});

const Video = (props) => {
    const { data, classes } = props;
    const { videotitle, video: { mediaItemUrl }, src } = data;

    return (
        <div className={classes.root}>
            {videotitle &&
                <Typography variant="h3" align="center" className={classes.videoTitle}>{videotitle}</Typography>
            }
            <Media
                type="video"
                src={src}
                dataSrc={mediaItemUrl}
                controls
            />
        </div>
    )
}

export default withStyles(styles)(Video);