import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    Grid,
    Typography,
    CircularProgress,
    Button,
    Paper,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Media } from "../../Components";
import styles from "./styles";

const Tiles = (props) => {
    const { data, classes } = props;
    const tabletPortrait = useMediaQuery("(min-width:768px)");

    return (
        <div className={classes.root}>
            {data ? (
                <React.Fragment>
                    <Grid
                        container
                        spacing={tabletPortrait ? 2 : 1}
                        justify="center"
                    >
                        {data.map((tile, idx) => (
                            <Grid item key={idx} xs={6} md={3} lg={2}>
                                <Paper
                                    className={classes.paper}
                                    component={tile.link ? RouterLink : "div"}
                                    to={
                                        tile.link &&
                                        tile.link.url &&
                                        "/" + tile.link.url.split("/")[4]
                                    }
                                >
                                    <div className={classes.imageWrap}>
                                        {tile.image && (
                                            <Media
                                                dataSrc={
                                                    tile.image.srcSet.split(
                                                        " "
                                                    )[0]
                                                }
                                                dataSrcSet={tile.image.srcSet}
                                                alt={tile.title}
                                            />
                                        )}
                                        {tile.title && (
                                            <Typography
                                                variant="h5"
                                                component="h3"
                                                className={classes.tileTitle}
                                            >
                                                {tile.title}
                                            </Typography>
                                        )}
                                    </div>
                                    {tile.link && (
                                        <div className={classes.actions}>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                disableElevation
                                                className={classes.tileButton}
                                                size="small"
                                                endIcon={<ArrowForwardIcon />}
                                            >
                                                Bekijk
                                            </Button>
                                        </div>
                                    )}
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </React.Fragment>
            ) : (
                <CircularProgress />
            )}
        </div>
    );
};

export default withStyles(styles)(Tiles);
