const styles = theme => ({
    pageHeader: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: '80px 12px',
        boxSizing: 'border-box',
        minHeight: '275px',
        marginTop: '64px',
        [theme.breakpoints.up('md')]: {
            padding: '80px 32px',
            minHeight: '375px'
        }
    },
    pageHeaderXL: {
        height: '80vh',
        [theme.breakpoints.up('sm')]: {
            minHeight: '640px'
        }
    },
    background: {
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        '&::before': {
            content: "''",
            background: 'linear-gradient(0deg, rgba(33,79,42,1) 0%, rgba(33,79,42,0.5018382352941176) 50%, rgba(33,79,42,0.2497373949579832) 100%)',
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            opacity: 0.75
        },
        '& img, & video': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'opacity .3s ease'
        }
    },
    pageHeaderContent: {
        position: 'relative',
        zIndex: 2,
        display: 'inherit',
        flexFlow: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit'
    },
    pageHeaderTitle: {
        textAlign: 'center'
    },
    pageHeaderButton: {
        marginTop: '4rem'
    },
    buttonIconRight: {
        marginLeft: '.5rem'
    },
    leaf: {
        position: 'absolute',
        right: '2rem',
        bottom: 0,
        transform: 'translateY(30%)',
        maxWidth: '20vw',
        maxHeight: '20vh',
        zIndex: 2
    },
    media: {
        '& $pageHeaderTitle': {
            color: 'white'
        }
    }
});

export default styles;