import { createMuiTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const themeColors = {
    primary: {
        main: "#84b134",
        dark: "#222",
        light: "#97ca3e",
        contrastText: "#fff",
    },
    secondary: {
        main: "#214f2a",
        dark: "",
        light: "#fff",
        contrastText: "",
    },
};

const themeTypography = {
    main: ["Convergence", "Helvetica", "sans-serif"].join(","),
    headings: {
        fontFamily: ["Francois One", "Helvetica", "sans-serif"].join(","),
        lineHeight: 1.1,
        color: themeColors.primary.main,
        margin: 0,
    },
};

const theme = createMuiTheme({
    palette: {
        ...themeColors,
    },
    typography: {
        useNextVariants: true,
        fontFamily: themeTypography.main,
        h1: {
            ...themeTypography.headings,
            fontWeight: 800,
            fontSize:
                "calc(38px + (70 - 38) * ((100vw - 320px) / (1600 - 320)));",
        },
        h2: {
            ...themeTypography.headings,
            fontWeight: 700,
            fontSize:
                "calc(32px + (48 - 32) * ((100vw - 320px) / (1600 - 320)));",
        },
        h3: {
            ...themeTypography.headings,
            fontWeight: 700,
            fontSize:
                "calc(30px + (42 - 30) * ((100vw - 320px) / (1600 - 320)));",
        },
        h4: {
            ...themeTypography.headings,
            fontWeight: 700,
            fontSize: "26px",
        },
        h5: {
            ...themeTypography.headings,
            fontWeight: 700,
            fontSize: "20px",
        },
        h6: {
            ...themeTypography.headings,
            fontWeight: 700,
            fontSize: "1rem",
        },
        body1: {
            color: "#555",
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                body: {
                    overflowY: "scroll",
                },
                video: {
                    background: "black",
                },
                "img, video": {
                    display: "block",
                    maxWidth: "100%",
                },
                /* Works on Chrome/Edge/Safari */
                "*::-webkit-scrollbar": {
                    width: "8px",
                },
                "*::-webkit-scrollbar-track": {
                    background: "#214f2a",
                },
                "*::-webkit-scrollbar-thumb": {
                    backgroundColor: "#84b134",
                    borderRadius: "20px",
                },
            },
        },
        MuiSkeleton: {
            root: {
                zIndex: 10,
                position: "fixed",
                top: 0,
                left: 0,
                backgroundColor: fade(themeColors.primary.main, 0.5),
            },
        },
        MuiButton: {
            root: {
                textTransform: "none",
                fontSize: "1.25rem",
                padding: "12px 16px",
            },
            contained: {
                color: "white",
                backgroundColor: themeColors.primary.main,
                "&:hover": {
                    backgroundColor: themeColors.primary.light,
                    "@media(hover: none)": {
                        backgroundColor: themeColors.primary.light,
                    },
                },
            },
            containedPrimary: {
                "&:hover": {
                    backgroundColor: themeColors.primary.light,
                },
            },
        },
    },
});

export default theme;
