const lazyMediaObserver = (media) => {
    const setPreloadedSrc = (el, attr, src) => {
        el.setAttribute(attr.split("-").pop(), src);
        el.removeAttribute(attr);
        el.getAttribute("data-muted") && setMuted(el);
        el.classList.remove("lazy-hidden");
    };

    const preloadImage = (target) => {
        let dataSrc = target.getAttribute("data-src");
        let dataSrcSet = target.getAttribute("data-srcset");
        dataSrc && setPreloadedSrc(target, "data-src", dataSrc);
        dataSrcSet && setPreloadedSrc(target, "data-srcset", dataSrcSet);
    };

    const setMuted = (el) => {
        el.muted = true;
        el.setAttribute("muted", "");
    };

    if ("IntersectionObserver" in window) {
        const config = {
            rootMargin: "150px 0px",
            threshold: 0,
        };

        const onIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    observer.unobserve(entry.target);
                    preloadImage(entry.target);
                }
            });
        };

        let observer = new IntersectionObserver(onIntersection, config);
        observer.observe(media);
    } else {
        // Fallback for old browsers
        preloadImage(media);
    }
};

export default lazyMediaObserver;
