import React, { createRef, useEffect } from "react";
import LazyMedia from "./LazyMedia";

const Media = (props) => {
    const {
        type = "img",
        src,
        dataSrc,
        dataSrcSet,
        dataDialogSrcSet,
        playsInline,
        loop,
        muted,
        autoPlay,
        height,
        className = "",
        onClick,
        controls = false,
        alt,
        preload = "metadata",
    } = props;

    const mediaEl = createRef();

    useEffect(() => {
        LazyMedia(mediaEl.current);
    }, [mediaEl]);

    return type === "img" ? (
        <img
            src={
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
            }
            ref={mediaEl}
            data-src={dataSrc}
            data-srcset={dataSrcSet}
            data-dialog-srcset={dataDialogSrcSet}
            height={height}
            className={className}
            onClick={onClick}
            alt={alt}
        />
    ) : (
        <video
            src={src}
            ref={mediaEl}
            data-src={dataSrc}
            playsInline={playsInline}
            autoPlay={autoPlay}
            data-muted={muted}
            loop={loop}
            className={className}
            muted
            controls={controls}
            preload={preload}
        />
    );
};

export default Media;
