const styles = theme => ({
    root: {
        maxWidth: '1600px',
        margin: '0 auto'
    },
    paper: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
        height: '100%',
        overflow: 'hidden',
        textDecoration: 'none'
    },
    tileTitle: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        color: 'white',
        zIndex: 1,
        padding: theme.spacing(2),
        fontWeight: 500
    },
    imageWrap: {
        position: 'relative',
        height: 0,
        paddingBottom: '60%',
        '& img': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: .5,
            pointerEvents: 'none',
            background: 'linear-gradient(0deg, rgba(33,79,42,1) 50%, rgba(33,79,42,0.2497373949579832) 100%)'
        },
    },
    tileImage: {
        objectFit: 'cover',
        height: '100%',
        width: '100%'
    },
    dialogImage: {
        maxHeight: 'calc(100vh - 150px)'
    },
    tileButton: {
        margin: 'auto 0 0 auto'
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1, 2)
    }
});

export default styles;