const styles = (theme) => ({
    pageRoot: {
        display: "flex",
        flexFlow: "column nowrap",
        minHeight: "100vh",
        transition: "opacity .5s ease",
        overflow: "hidden",
    },
    linearProgress: {
        zIndex: 99,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
    },
    circularProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    pageContent: {
        padding: "32px 12px",
        fontSize: "1.125rem",
        lineHeight: 1.75,
        [theme.breakpoints.up("md")]: {
            padding: "4rem 32px",
            fontSize: "1.25rem",
        },
    },
    imgPlaceholder: {
        background: "black",
    },
    flexContentBlock: {
        margin: "0 0 6rem",
    },
    pageFooter: {
        background: theme.palette.primary.main,
        padding: "3rem 2rem",
        marginTop: "auto",
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.up("md")]: {
            fontSize: "1.15rem",
        },
    },
    alignLeft: {
        float: "left",
        marginRight: theme.spacing(4),
    },
});

export default styles;
