import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { withStyles } from "@material-ui/core/styles";
import { Media } from "../../Components";
import styles from "./styles";
import leaf from "./assets/leaf.png";

const PageHeader = (props) => {
    const { data, classes, frontPage } = props;
    const [pageHeader, setPageHeader] = useState(null);
    const [media, setMedia] = useState(null);

    // Effects
    useEffect(() => {
        setPageHeader(data.pageHeader);
        setMedia(data.pageHeader.backgroundmedia);
    }, [data]);

    return (
        <header
            className={classNames(classes.pageHeader, {
                [classes.pageHeaderXL]: frontPage,
                [classes.media]: media,
            })}
        >
            <div className={classes.pageHeaderContent}>
                {pageHeader && (
                    <React.Fragment>
                        <Typography
                            variant="h1"
                            className={classes.pageHeaderTitle}
                            dangerouslySetInnerHTML={{
                                __html:
                                    pageHeader.title ||
                                    data.title ||
                                    "Geen titel",
                            }}
                        />
                        {pageHeader.button && (
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.pageHeaderButton}
                                component={Link}
                                to={pageHeader.button.url.replace(
                                    window.location.origin,
                                    ""
                                )}
                            >
                                {pageHeader.button.title}
                                <LocalOfferIcon
                                    className={classes.buttonIconRight}
                                />
                            </Button>
                        )}
                    </React.Fragment>
                )}
            </div>
            {media && (
                <div className={classes.background}>
                    {media.mimeType.includes("video") ? (
                        <Media
                            type="video"
                            title={media.altText || media.title}
                            dataSrc={media.mediaItemUrl}
                            playsInline
                            autoPlay
                            muted
                            loop
                            height={
                                media.mediaDetails && media.mediaDetails.height
                            }
                        />
                    ) : (
                        media.srcSet && (
                            <Media
                                dataSrc={media.srcSet.split(" ")[0]}
                                dataSrcSet={media.srcSet
                                    .split(", ")
                                    .slice(0, 4)
                                    .join(",")}
                                dataDialogSrcSet={media.srcSet}
                                alt={media.altText || media.title}
                            />
                        )
                    )}
                </div>
            )}
            <img src={leaf} alt="" className={classes.leaf} />
        </header>
    );
};

export default withStyles(styles)(PageHeader);
