import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Tabs, Tab, Paper, Fab } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import FacebookIcon from "@material-ui/icons/Facebook";
import styles from "./styles";

const Navigation = (props) => {
    const { classes, items } = props;
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(false);
    const { pathname: routerPath } = useLocation();

    useEffect(() => {
        items.map(
            (menuItem, idx) =>
                routerPath.includes(menuItem.path) && setActiveIndex(idx)
        );
    }, [items, routerPath]);

    const handleChange = (event, newValue) => setActiveIndex(newValue);
    const toggleMenu = () => setMenuOpen((prevState) => !prevState);

    return (
        <React.Fragment>
            <div className={classes.headerNav}>
                <Tabs
                    value={activeIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    classes={{
                        flexContainer: classes.navTabFlexContainer,
                    }}
                >
                    {items.map((menuItem) => (
                        <Tab
                            key={menuItem.id}
                            to={menuItem.path}
                            label={menuItem.label}
                            component={RouterLink}
                            className={classes.navTab}
                        />
                    ))}
                </Tabs>

                {items.length && (
                    <div className={classes.social}>
                        <a
                            href="https://nl-nl.facebook.com/tuincentrumjohnkuipers"
                            target="_blank"
                            rel="noreferrer"
                            className={classes.socialLink}
                        >
                            <FacebookIcon color="inherit" /> Volg ons
                        </a>
                    </div>
                )}
            </div>

            <div className={classes.mobileNav}>
                <Fab
                    variant="extended"
                    color="secondary"
                    onClick={toggleMenu}
                    component="div"
                >
                    Menu
                    <MenuIcon className={classes.fabIcon} />
                </Fab>
                <Paper
                    className={classNames(classes.mobileNavMenu, {
                        [classes.activeNavMenu]: menuOpen,
                    })}
                >
                    {items.map((menuItem) => (
                        <RouterLink
                            key={menuItem.id}
                            to={menuItem.path}
                            children={menuItem.label}
                            className={classes.navLink}
                            onClick={toggleMenu}
                        />
                    ))}
                </Paper>
            </div>
        </React.Fragment>
    );
};

export default withStyles(styles)(Navigation);
