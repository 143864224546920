const styles = (theme) => ({
    siteHeader: {
        zIndex: 50,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        padding: "0 12px",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "64px",
        boxSizing: "border-box",
        boxShadow: "0 0 8px 0px rgba(0, 0, 0, .3)",
        [theme.breakpoints.up("sm")]: {
            padding: "0 32px",
        },

        background: "white",
    },
    siteHeaderLogo: {
        flexShrink: 0,
        display: "block",
        maxWidth: "250px",
        borderRadius: "0 0 16px 16px",
        borderWidth: "0 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "white",
        overflow: "hidden",
        alignSelf: "flex-start",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "40vw",
        },
    },
    dialogHeading: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            fontSize: "1.5rem",
        },
    },
    dialogButtons: {
        marginTop: theme.spacing(2),
    },
    dialogContent: {
        padding: "1rem",
        [theme.breakpoints.up("md")]: {
            padding: "2rem",
        },
    },
    dialogPaper: {
        margin: "12px",
    },
    navActions: {
        marginLeft: "auto",
    },
    navActionButton: {
        padding: theme.spacing(1, 2),
        [theme.breakpoints.up("sm")]: {
            fontSize: "1rem",
        },
    },
    social: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.main,
        margin: theme.spacing(1, 0),

        "& svg": {
            marginRight: theme.spacing(0.5),
            width: "2rem",
            height: "2rem",
        },
    },
    socialLink: {
        display: "inherit",
        alignItems: "inherit",
        color: "inherit",
        textDecoration: "none",
        transition: "all .2s ease",

        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
});

export default styles;
