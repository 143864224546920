import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  ButtonGroup,
  Button,
  Dialog,
  DialogContent,
  Typography,
  AppBar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { GQL_QUERY_PRIMARY_MENU, GQL_QUERY_ACF_OPTIONS } from "./graphql";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import TimeToLeaveIcon from "@material-ui/icons/TimeToLeave";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Navigation } from "../Navigation";
import FacebookIcon from "@material-ui/icons/Facebook";
import logo from "./logo.jpg";
import styles from "./styles";

const SiteHeader = (props) => {
  const { classes } = props;
  const [menuItems, setMenuItems] = useState([]);
  const [acfOptions, setAcfOptions] = useState({
    openingstijden: "",
  });
  const [showOpeningsDialog, setShowOpeningsDialog] = useState(false);
  const tabletPortrait = useMediaQuery("(min-width:768px)");

  const menuQuery = useQuery(GQL_QUERY_PRIMARY_MENU, {
    variables: {
      id: "Hoofdmenu",
    },
    fetchPolicy: "cache-first",
  });

  const acfOptionsQuery = useQuery(GQL_QUERY_ACF_OPTIONS);

  useEffect(() => {
    if (menuQuery.data && menuQuery.data.menu) {
      setMenuItems(menuQuery.data.menu.menuItems.nodes);
    }
  }, [menuQuery]);

  useEffect(() => {
    if (acfOptionsQuery.data && acfOptionsQuery.data) {
      setAcfOptions(acfOptionsQuery.data.opties.optiesGroep);
    }
  }, [acfOptionsQuery]);

  const toggleOpeningsDialog = () => {
    setShowOpeningsDialog((prevState) => !prevState);
  };

  console.log("optionsQuery", acfOptions);

  return (
    <React.Fragment>
      <AppBar className={classes.siteHeader}>
        <Link to="/" className={classes.siteHeaderLogo}>
          <img src={logo} alt="Groencentrum Kuipers" />
        </Link>

        <Navigation items={menuItems} />

        <ButtonGroup
          className={classes.navActions}
          disableElevation
          size={tabletPortrait ? "medium" : "small"}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<QueryBuilderIcon />}
            className={classes.navActionButton}
            onClick={toggleOpeningsDialog}
          >
            Openingstijden
          </Button>
          {tabletPortrait && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<TimeToLeaveIcon />}
              className={classes.navActionButton}
              href="https://www.google.com/maps/dir//Tuincentrum+John+Kuipers+B.V.,+Beuningerstraat,+De+Lutte/@52.3347356,6.9392438,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47b8181a83ce6aaf:0x6922413b4bb93e0f!2m2!1d7.0092832!2d52.3346514"
              target="_blank"
            >
              Route
            </Button>
          )}
        </ButtonGroup>
      </AppBar>

      <Dialog
        open={showOpeningsDialog}
        onClose={toggleOpeningsDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent className={classes.dialogContent}>
          <Typography variant="h4" className={classes.dialogHeading}>
            Openingstijden
          </Typography>
          {acfOptions?.openingstijden && (
            <Typography
              dangerouslySetInnerHTML={{ __html: acfOptions.openingstijden }}
            />
          )}
          {!tabletPortrait && (
            <div className={classes.social}>
              <a
                href="https://nl-nl.facebook.com/tuincentrumjohnkuipers"
                target="_blank"
                rel="noreferrer"
                className={classes.socialLink}
              >
                <FacebookIcon color="inherit" /> Volg ons
              </a>
            </div>
          )}
          <ButtonGroup
            className={classes.dialogButtons}
            disableElevation
            size={tabletPortrait ? "medium" : "small"}
          >
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={toggleOpeningsDialog}
            >
              Sluiten
            </Button>
            {!tabletPortrait && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<TimeToLeaveIcon />}
                href="https://www.google.com/maps/dir//Tuincentrum+John+Kuipers+B.V.,+Beuningerstraat,+De+Lutte/@52.3347356,6.9392438,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47b8181a83ce6aaf:0x6922413b4bb93e0f!2m2!1d7.0092832!2d52.3346514"
                target="_blank"
              >
                Route
              </Button>
            )}
          </ButtonGroup>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default withStyles(styles)(SiteHeader);
