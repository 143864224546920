import { gql } from "@apollo/client";

export const GQL_QUERY_FRONTPAGE_BY_SLUG = gql`
  query Pages {
    pages {
      nodes {
        slug
        isFrontPage
      }
    }
  }
`;

export const GQL_QUERY_PAGE_BY_SLUG = gql`
  query GetPageBySlug($slug: ID!) {
    page(id: $slug, idType: URI) {
      id
      isFrontPage
      title
      content
      pageHeader {
        title
        fullscreen
        backgroundmedia {
          mimeType
          mediaItemUrl
          srcSet
          altText
          title
          mediaDetails {
            height
          }
        }
        button {
          title
          url
        }
      }
      flexContent {
        flexContent {
          ... on Page_Flexcontent_FlexContent_Tiles {
            tiles {
              title
              image {
                id
                mediaItemUrl
                srcSet
                altText
                title
              }
              link {
                target
                title
                url
              }
            }
          }
          ... on Page_Flexcontent_FlexContent_Richttext {
            richtext {
              align
              image {
                id
                altText
                title
                mediaItemUrl
                srcSet
                mediaDetails {
                  height
                }
              }
              wysiwyg
              button {
                target
                title
                url
              }
            }
          }
          ... on Page_Flexcontent_FlexContent_Gallery {
            gallerytitle
            gallery {
              mediaItemUrl
              srcSet
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                  width
                }
              }
            }
          }
          ... on Page_Flexcontent_FlexContent_Video {
            videotitle
            video {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;
