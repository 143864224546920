import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Media } from "../../Components";
import styles from "./styles";

const RichText = (props) => {
    const { data, classes } = props;
    const { align } = data;

    return (
        <div
            className={classNames(classes.richText, {
                [classes.imageRight]: align === "right",
            })}
        >
            <Grid container justify="center" spacing={8}>
                <Grid item xs={12} md={6} lg={4} className={classes.article}>
                    {data.wysiwyg && (
                        <Typography
                            component="div"
                            dangerouslySetInnerHTML={{ __html: data.wysiwyg }}
                        />
                    )}
                    {data.button && (
                        <Button
                            variant="outlined"
                            color="primary"
                            target={data.button.target}
                            href={data.button.url}
                            className={classes.button}
                            endIcon={<ArrowForwardIcon />}
                            disableElevation
                        >
                            {data.button.title}
                        </Button>
                    )}
                </Grid>
                {data.image && (
                    <Grid item xs={12} md={6} lg={5}>
                        <div className={classes.imageWrapper}>
                            <Media
                                dataSrc={data.image.srcSet.split(" ")[0]}
                                dataSrcSet={data.image.srcSet}
                            />
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default withStyles(styles)(RichText);
